import React from 'react';
import {
  Link,
  Paragraph,
  PageHero,
  PageWithSubNav,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/resources.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Typefaces" subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Design Assets"
        tierThree="Typefaces"
      />

      <SectionSubhead>Helvetica</SectionSubhead>
      <Paragraph>
        When designing for the web, we use Helvetica. We also have font stack
        fallbacks of Arial and sans-serif.
      </Paragraph>

      <SectionSubhead>Roboto</SectionSubhead>
      <Paragraph>
        When designing for Android, we design with{' '}
        <Link href="https://material.io/guidelines/resources/roboto-noto-fonts.html">
          Roboto
        </Link>
        .
      </Paragraph>

      <SectionSubhead>San Francisco</SectionSubhead>
      <Paragraph>
        When designing for Apple, we design with{' '}
        <Link href="https://developer.apple.com/fonts/">San Francisco</Link>.
        Follow Apple’s{' '}
        <Link href="https://developer.apple.com/ios/human-interface-guidelines/visual-design/typography/">
          Human Interface Guidelines
        </Link>{' '}
        for guidance on dynamic type sizes and font variants.
      </Paragraph>

      <SectionSubhead>Segoe</SectionSubhead>
      <Paragraph>
        When designing for Windows products, we design with{' '}
        <Link href="https://docs.microsoft.com/en-us/windows/uwp/design/downloads/index">
          Segoe UI
        </Link>
        .
      </Paragraph>
    </PageWithSubNav>
  );
};

export default IndexPage;
